@import "_variables";

.mixin-curved-left(@radius: 30%) {
  border-radius: @radius 0 0 0;
}

.mixin-curved-right(@radius: 30%) {
  border-radius: 0 @radius 0 0;
}

.mixin-faded-background (@styleIndex, @percentage) {
  @color: "color-accent-@{styleIndex}";
  background-color: fade(@@color, @percentage);
}

.media-phone(@rules) {
  @media screen and (max-width: @screen-phone) {
    @rules();
  }
}

.media-tablet(@rules) {
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    @rules();
  }
}

.media-desktop(@rules) {
  @media screen and (min-width: @screen-desktop) {
    @rules();
  }
}

.media-lt-desktop(@rules) {
  @media screen and (max-width: @screen-desktop - 1) {
    @rules();
  }
}

.media-custom-max(@width, @rules) {
  @media screen and (max-width: @width) {
    @rules();
  }
}

.media-custom-min(@width, @rules) {
  @media screen and (min-width: @width) {
    @rules();
  }
}

.media-gt-phone(@rules) {
  @media (min-width: (@screen-phone + 1px)) {
    @rules();
  }
}

.media-gt-tablet(@rules) {
  @media (min-width: (@screen-tablet + 1px)) {
    @rules();
  }
}

.media-lt-tablet(@rules) {
  @media (max-width: (@screen-tablet - 1px)) {
    @rules();
  }
}

