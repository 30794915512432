@import "_variables";
@import "_mixins";

body {
  font-family: @font-main;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: @color-text;
}

.text-small {
  font-size: 16px;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6 {
  color: @color-black;
  font-family: @font-secondary;
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;

  .media-lt-tablet({
    font-size: 44px;
  })
}

h2 {
  font-size: 44px;
  font-style: normal;
  font-weight: 700;

  .media-lt-tablet({
    font-size: 36px;
  })

}

h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

h4 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 12px;
}

h5 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;
}

h6 {
  font-family: @font-main;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


button {
  cursor: pointer;
}

.button {
  display: inline-block;
  margin: 0;
  padding: 14px 40px;
  border-radius: 30px;
  font-weight: 900;
  border: none;
  transition: all 0.3s ease-out;
  position: relative;

  &:hover {
    border-radius: 30px 0 30px 30px;
  }

  &.button {
    &-default {
      background-color: @color-accent;
      color: @color-white;

      &[href]:not(.no-external-link-icon):not([href*='telemann.de']):not([href^='#']):not([href^='/']) {
        &::after {
          background-image: url('../assets/images/icons/external-link-alt-white.svg');
        }
      }

      &:hover {
        background-color: @color-accent-dark;
      }
    }

    &-white {
      background-color: @color-white;
      border: 1px solid @color-accent;
      color: @color-accent;
    }
  }
}

ul {
  &.list-bulleted {
    li {
      padding-left: 80px;
      display: block;
      position: relative;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        left: 40px;
        top: 8px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: @color-accent;
      }
    }
  }
}

ol {
  li {
    padding-left: 60px;
    display: block;
    position: relative;
    margin-bottom: 20px;
    counter-increment: item;

    &::before {
      content: counter(item);
      display: block;
      position: absolute;
      width: 60px;
      left: 0;
      top: 5px;
      font-family: Avenir, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
    }
  }
}
