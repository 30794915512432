@import "node_modules/animate.css/animate.min.css";
@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import 'node_modules/lightgallery/css/lightgallery-bundle.min.css';
@import "node_modules/primeng/resources/primeng.css";
* {
  box-sizing: border-box;
}
p {
  margin: 0 0 20px 0;
}
body,
html {
  margin: 0;
  padding: 0;
}
body {
  overflow: scroll;
}
menu,
ul,
li,
figure {
  padding: 0;
  margin: 0;
}
ul,
li {
  list-style: none;
}
a {
  color: #000000;
  text-decoration: none;
  font-weight: normal;
}
.p-accordion-tab-active .accordion-header {
  background-color: #F6F8F9 !important;
  border-bottom: 1px solid #E2E3E5;
}
.p-accordion-tab-active .accordion-header--container::before {
  transform: translateY(-4px) rotate(45deg) !important;
}
.main-content {
  min-height: calc(100vh - 432px);
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./assets/fonts/AvenirLTPro-Book.woff2) format("woff2"), url(./assets/fonts/AvenirLTPro-Book.woff) format("woff");
}
@font-face {
  font-family: 'Avenir';
  src: local("Avenir"), url(./assets/fonts/AvenirLTPro-Medium.woff2) format("woff2"), url(./assets/fonts/AvenirLTPro-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: local("Avenir"), url(./assets/fonts/AvenirLTPro-Black.woff2) format("woff2"), url(./assets/fonts/AvenirLTPro-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(./assets/fonts/maiolapro_bold-webfont.woff2) format("woff2"), url(./assets/fonts/maiolapro_bold-webfont.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(./assets/fonts/maiolapro_bold_italic-webfont.woff2) format("woff2"), url(./assets/fonts/maiolapro_bold_italic-webfont.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(./assets/fonts/maiolapro_italic-webfont.woff2) format("woff2"), url(./assets/fonts/maiolapro_italic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(./assets/fonts/maiolapro_regular-webfont.woff2) format("woff2"), url(./assets/fonts/maiolapro_regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #292A2A;
}
.text-small {
  font-size: 16px;
  line-height: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: 'MaiolaPro', serif;
  margin-top: 0;
  line-height: 1.1;
}
h1 {
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 921px) {
  h1 {
    font-size: 44px;
  }
}
h2 {
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
}
@media (max-width: 921px) {
  h2 {
    font-size: 36px;
  }
}
h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
h4 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 12px;
}
h5 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;
}
h6 {
  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 20px 0 10px 0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
button {
  cursor: pointer;
}
.button {
  display: inline-block;
  margin: 0;
  padding: 14px 40px;
  border-radius: 30px;
  font-weight: 900;
  border: none;
  transition: all 0.3s ease-out;
  position: relative;
}
.button:hover {
  border-radius: 30px 0 30px 30px;
}
.button.button-default {
  background-color: #D11313;
  color: #FFFFFF;
}
.button.button-default[href]:not(.no-external-link-icon):not([href*='telemann.de']):not([href^='#']):not([href^='/'])::after {
  background-image: url('./assets/images/icons/external-link-alt-white.svg');
}
.button.button-default:hover {
  background-color: #AF1917;
}
.button.button-white {
  background-color: #FFFFFF;
  border: 1px solid #D11313;
  color: #D11313;
}
ul.list-bulleted li {
  padding-left: 80px;
  display: block;
  position: relative;
  margin-bottom: 20px;
}
ul.list-bulleted li::before {
  content: '';
  position: absolute;
  left: 40px;
  top: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #D11313;
}
ol li {
  padding-left: 60px;
  display: block;
  position: relative;
  margin-bottom: 20px;
  counter-increment: item;
}
ol li::before {
  content: counter(item);
  display: block;
  position: absolute;
  width: 60px;
  left: 0;
  top: 5px;
  font-family: Avenir, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
}
body {
  padding-top: 80px;
}
.container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-position: center center;
  background-repeat: repeat;
}
.container-boxed {
  max-width: 1200px;
}
@media screen and (max-width: 1199px) {
  .container-boxed {
    padding: 0 20px;
  }
  .container-boxed .container-boxed {
    padding: 0;
  }
}
.container-full {
  max-width: none;
  width: 100%;
}
.main-content a:not(.button) {
  color: #D11313;
  text-decoration: none;
}
.main-content a:not(.button):hover {
  color: #AF1917;
}
.main-content p a:not(.button) {
  text-decoration: underline;
}
