@import "_variables";
@import "_mixins";

body {
  padding-top: 80px;
}

.container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-position: center center;
  background-repeat: repeat;
}

.container-boxed {
  max-width: 1200px;
  .media-lt-desktop({
    .container-boxed {
      padding: 0;
    }

    padding: 0 20px;
  })
}

.container-full {
  max-width: none;
  width: 100%;
}

.main-content {
  a:not(.button) {
    color: @color-accent;
    text-decoration: none;

    &:hover {
      color: @color-accent-dark;
    }
  }

  p {
    a:not(.button) {
      text-decoration: underline;
    }
  }
}
