@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(../assets/fonts/AvenirLTPro-Book.woff2) format("woff2"), url(../assets/fonts/AvenirLTPro-Book.woff) format("woff");
}

@font-face {
  font-family: 'Avenir';
  src: local("Avenir"), url(../assets/fonts/AvenirLTPro-Medium.woff2) format("woff2"), url(../assets/fonts/AvenirLTPro-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local("Avenir"), url(../assets/fonts/AvenirLTPro-Black.woff2) format("woff2"), url(../assets/fonts/AvenirLTPro-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(../assets/fonts/maiolapro_bold-webfont.woff2) format("woff2"), url(../assets/fonts/maiolapro_bold-webfont.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(../assets/fonts/maiolapro_bold_italic-webfont.woff2) format("woff2"), url(../assets/fonts/maiolapro_bold_italic-webfont.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(../assets/fonts/maiolapro_italic-webfont.woff2) format("woff2"), url(../assets/fonts/maiolapro_italic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MaiolaPro';
  src: local("MaiolaPro"), url(../assets/fonts/maiolapro_regular-webfont.woff2) format("woff2"), url(../assets/fonts/maiolapro_regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
