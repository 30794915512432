@import "_colors";

* {
  box-sizing: border-box;
}

p {
  margin: 0 0 20px 0;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  overflow: scroll;
}

menu, ul, li, figure {
  padding: 0;
  margin: 0;
}

ul, li {
  list-style: none;
}

a {
  color: @color-black;
  text-decoration: none;
  font-weight: normal;
}

.p-accordion-tab-active {
  .accordion-header {
    background-color: @color-dark !important;
    border-bottom: 1px solid @color-light;
  }

  .accordion-header--container {
    &::before {
      transform: translateY(-4px) rotate(45deg) !important;
    }
  }
}

.main-content {
  min-height: calc(100vh - 432px);
}
